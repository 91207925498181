<template>
    <div class="distribute-seting">
        <!-- <div v-if="showBar" class="distribute-seting-bar">
            <div class="bar-text">
                设置后，活动推广页将优先展示您的联系方式
                <span class="example" @click="showExamplePopup = true">示例</span>
            </div>
            <div class="bar-close" @click="showBar = false">
                <van-icon name="cross" color="#B2B2B2" size="0.32rem" />
            </div>
        </div> -->
        <div class="distribute-seting-save">
            <div class="save-button" @click="submitSeting">保存</div>
        </div>
        <div class="distribute-seting-form">
            <!-- 头像 -->
            <div class="form-item form-item-avatar">
                <div class="form-label">
                    头像
                    <span class="required">*</span>
                </div>
                <div class="form-input" @click="showAvatarPopup = false">
                    <img v-if="userInfo.avatar" class="avatar" :src="userInfo.avatar" alt="" />
                    <!-- <img class="arror-icon" src="@/assets/images/arror_right_2.png" alt="" /> -->
                </div>
            </div>
            <!-- 基本信息 -->
            <div class="form-item form-item-base">
                <div class="base-item base-item-name border-bottom-1">
                    <div class="form-label">
                        姓名
                        <span class="required">*</span>
                    </div>
                    <div class="form-input">
                        <input
                            v-model.trim="userInfo.name"
                            type="text"
                            disabled
                            maxlength="24"
                            placeholder="请输入"
                            autocomplete="off"
                        />
                    </div>
                </div>
                <div class="base-item base-item-phone border-bottom-1">
                    <div class="form-label">
                        手机号
                        <span class="required">*</span>
                    </div>
                    <div class="form-input">
                        <input
                            v-model.trim="userInfo.phone"
                            type="tel"
                            maxlength="11"
                            disabled
                            placeholder="请输入"
                            autocomplete="off"
                            @input="phoneInput"
                        />
                    </div>
                </div>
                <div class="base-item base-item-position">
                    <div class="form-label">
                        职位
                        <!-- <span class="required">*</span> -->
                    </div>
                    <div class="form-input">
                        <input
                            v-model.trim="userInfo.position"
                            type="text"
                            maxlength="15"
                            placeholder="请输入"
                            autocomplete="off"
                        />
                    </div>
                </div>
            </div>
            <!-- 企微 -->
            <div class="form-item form-item-qw">
                <div class="form-title">微信二维码</div>
                <div class="form-code">
                    <div class="checkbox-wrap">
                        <van-radio-group v-model="userInfo.codeType" direction="horizontal" icon-size="0.48rem">
                            <van-radio name="1" icon-size="0.48rem">
                                当前企微码
                                <template #icon="props">
                                    <img class="checkbox-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                                </template>
                            </van-radio>
                            <van-radio name="0">
                                上传二维码
                                <template #icon="props" icon-size="0.48rem">
                                    <img class="checkbox-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                                </template>
                            </van-radio>
                        </van-radio-group>
                    </div>
                    <div v-show="userInfo.codeType === '1'" class="code-wrap">
                        <img
                            v-if="userInfo.qwCode"
                            class="code-image"
                            :src="userInfo.qwCode"
                            @click="onPreviewImg(userInfo.qwCode)"
                            alt=""
                        />
                    </div>
                    <div v-show="userInfo.codeType === '0'" class="code-wrap">
                        <div class="upload-code">
                            <div v-if="!userInfo.otherCode" class="upload-code-btn" @click="onCheckImg('otherCode')">
                                <img src="@/assets/mine/add.svg" alt="" />
                            </div>
                            <div v-if="userInfo.otherCode" class="upload-code-btn">
                                <img
                                    v-imgCDN
                                    :src="userInfo.otherCode"
                                    @click="onPreviewImg(userInfo.otherCode)"
                                    alt=""
                                />
                                <button v-if="!codeLoading" class="remove-pic" @click="onDeleteImg('otherCode')">
                                    <img src="@/assets/images/remove_icon.png" alt="" />
                                </button>
                                <div v-if="codeLoading" class="loading-area">
                                    <van-loading type="spinner" size="0.32rem" color="#fff" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-item form-item-information">
                <div class="form-title">展示信息</div>
                <div class="bar-text">
                    设置后，活动推广页将展示您选中的信息
                    <span class="example" @click="showExamplePopup = true">示例</span>
                </div>
                <div class="set-list">
                    <div
                        v-for="(item, index) in setList"
                        :key="index"
                        :class="['set-item', { 'set-item-select': item.isSelect, 'set-item-empty': !item.value }]"
                        @click="clickSetItem(item, index)"
                    >
                        {{ item.text }}
                    </div>
                </div>
            </div>
        </div>
        <!-- 示例弹窗 -->
        <van-popup
            v-model="showExamplePopup"
            position="bottom"
            round
            :style="{ height: '11.8rem' }"
            :overlay-style="{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }"
        >
            <div class="example-popup">
                <div class="popup-header">
                    <div class="header-title">示例</div>
                    <div class="header-close">
                        <img
                            class="close-icon"
                            src="@/assets/images/close_black.png"
                            @click="showExamplePopup = false"
                            alt=""
                        />
                    </div>
                </div>
                <div class="example-popup-content">
                    <div class="content-text">
                        <p>1、直播间屏蔽店铺二维码</p>
                        <p>2、优先展示推广员的引流信息</p>
                    </div>
                    <div class="content-image">
                        <img class="example-image" src="@/assets/images/exmaple_image.png" alt="" />
                    </div>
                </div>
            </div>
        </van-popup>
        <!-- 头像弹窗 -->
        <van-popup
            v-model="showAvatarPopup"
            position="bottom"
            round
            :style="{ height: '6.92rem' }"
            :overlay-style="{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }"
        >
            <div class="avatar-popup">
                <div class="popup-header">
                    <div class="header-title">头像</div>
                    <div class="header-close">
                        <img
                            class="close-icon"
                            src="@/assets/images/close_black.png"
                            @click="showAvatarPopup = false"
                            alt=""
                        />
                    </div>
                </div>
                <div class="avatar-popup-content">
                    <img class="avatar" :src="userInfo.avatar" @click="onPreviewImg(userInfo.avatar)" alt="" />
                    <div @click="onCheckImg('avatar')" class="avatar-change">更换</div>
                </div>
            </div>
        </van-popup>
        <h5-cropper ref="cropper" hide-input :option="cropperOptions" @get-file="getCropperFile" />
        <!-- 文件上传 -->
        <input v-show="false" type="file" ref="avatarInput" @change="imgFileChange" />
        <input v-show="false" type="file" ref="otherCodeInput" @change="imgFileChange" />
    </div>
</template>

<script>
import { uploadImg } from '@/globalWidget/utils/upload';
import { getCookie } from '@/utils/cookies';
// import { debounce } from '@/utils/tools';
import { Loading as vanLoading, ImagePreview } from 'vant';
import H5Cropper from './components/cropper.vue';

export default {
    name: 'DistributeSeting',
    components: {
        [ImagePreview.Component.name]: ImagePreview.Component,
        H5Cropper,
        vanLoading
    },
    data() {
        return {
            showBar: true,
            showExamplePopup: false,
            showAvatarPopup: false,
            codeLoading: false, // 控制图片上传加载状态
            saveLoading: false,

            uploadType: 'otherCode', // 上传类型 otherCode：二维码, avatar：头像
            activeIcon: require('@/assets/images/checkbox_check_icon.png'),
            inactiveIcon: require('@/assets/images/checkbox_nocheck_icon.png'),

            cropperOptions: {
                confirmButtonBackgroundColor: '#1472FF',
                canScale: true
            },
            userInfo: {
                avatar: '',
                name: '',
                phone: '',
                position: '',
                codeType: '1', // 1: 当前二维码 0: 上传二维码
                qwCode: '',
                otherCode: ''
            },

            // 之前展示设置放在别的页面每点击一次按钮，就向后端发生请求保存一次
            setList: [
                {
                    id: 1,
                    text: '头像',
                    field: 'pic_url',
                    value: '',
                    isSelect: false
                    // callback: debounce((item) => {
                    //     this.saveSeting(item);
                    // })
                },
                {
                    id: 2,
                    text: '职位',
                    field: 'title',
                    value: '',
                    isSelect: false
                    // callback: debounce((item) => {
                    //     this.saveSeting(item);
                    // })
                },
                {
                    id: 3,
                    text: '手机号',
                    field: 'phone',
                    value: '',
                    isSelect: false
                    // callback: debounce((item) => {
                    //     this.saveSeting(item);
                    // })
                },
                {
                    id: 4,
                    text: '微信',
                    field: 'qy_wechat',
                    value: '',
                    isSelect: false
                    // callback: debounce((item) => {
                    //     this.saveSeting(item);
                    // })
                }
            ]
        };
    },
    created() {
        this.getDistributeSeting();
    },
    methods: {
        // 点击信息设置项
        clickSetItem(item, index) {
            if (!this.setList[index].isSelect) {
                if (item.field === 'title' && !this.userInfo.position) {
                    this.$toast('未填写职位信息，无法在活动推广页展示');
                    return;
                }
            }
            this.setList[index].isSelect = !this.setList[index].isSelect;

            // if (!item.value) {
            //     this.$dialog
            //         .confirm({
            //             message: '请前往 【推广引流设置】<br/>设置个人信息',
            //             width: '6rem',
            //             confirmButtonText: '去设置',
            //             confirmButtonColor: '#1472ff',
            //             overlayStyle: { backgroundColor: 'rgba(0, 0, 0, 0.4)' },
            //             closeOnClickOverlay: true,
            //             className: 'message-tips-dialog',
            //             getContainer: () => document.querySelector('.distribute-seting')
            //         })
            //         .then(() => {
            //             this.goSeting();
            //         })
            //         .catch(() => {
            //             console.log('cancel');
            //         });
            //     return;
            // }
            // item.callback(item);
        },
        // 保存推广设置
        // saveSeting(item) {
        //     const params = {
        //         app_id: getCookie('with_app_id'),
        //         user_id: localStorage.getItem('userId'),
        //         select_option: item.field
        //     };
        //     item.isSelect = !item.isSelect;
        //     this.$ajax('set_distribute_type', params)
        //         .then((res) => {
        //             console.log('resssss', res);
        //             if (res.data.code === 0) {
        //                 console.log('设置成功', res.data);
        //                 // this.$toast('设置成功');
        //             } else {
        //                 this.$toast('设置失败');
        //             }
        //         })
        //         .catch(() => {
        //             this.$toast('设置失败');
        //         });
        // },
        // 获取已设置的信息
        getDistributeSeting() {
            const params = {
                app_id: getCookie('with_app_id'),
                user_id: localStorage.getItem('userId')
            };
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
            const getInfo = Promise.all([
                this.$ajax('get_distribute_seting', params),
                this.$ajax('get_qwuser_info', params)
            ]);
            getInfo
                .then((res) => {
                    const setInfo = res[0].data;
                    const qwInfo = res[1].data;
                    if (setInfo.code === 0 && qwInfo.code === 0) {
                        this.userInfo.avatar = setInfo.data.pic_url || qwInfo.data.pic_url;
                        this.userInfo.name = setInfo.data.user_name || qwInfo.data.user_name;
                        this.userInfo.phone = setInfo.data.phone || qwInfo.data.phone;
                        this.userInfo.position = setInfo.data.title || qwInfo.data.title;
                        this.userInfo.codeType = String(setInfo.data.qy_wechat_is_current);
                        this.userInfo.qwCode = setInfo.data.qw_code || qwInfo.data.qw_code;
                        this.userInfo.otherCode =
                            String(setInfo.data.qy_wechat_is_current) === '0' ? setInfo.data.qy_wechat_qr_code : '';
                        this.setList.forEach((item) => {
                            item.value = setInfo.data[item.field];
                            item.isSelect = setInfo.data.select_options.includes(item.field);
                        });
                        this.$toast.clear();
                    } else {
                        this.$toast('网络错误');
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$toast('网络错误');
                });
        },

        // 触发图片选择
        onCheckImg(type) {
            this.uploadType = type;
            this.$refs[this.uploadType + 'Input'].click();
        },
        // 保存上传的图片
        onUploadImg(url) {
            this.userInfo[this.uploadType] = url;
        },
        // 删除图片
        onDeleteImg(type) {
            this.userInfo[type] = '';
        },

        // 处理选择的图片
        imgFileChange(e) {
            const currentUrl = this.userInfo[this.uploadType];
            const fileList = e.target.files;
            if (!fileList || fileList.length === 0) {
                this.onUploadImg(currentUrl);
                return;
            }

            const inputFile = fileList[0];
            // file.size : 图片大小(字节数)，限制不能超过10M
            if (inputFile.size > 10 * 1024 * 1024) {
                this.$toast('图片大小需小于10M');
                this.onUploadImg(currentUrl);
                return;
            }
            // file.type：图片类型仅支持 JPG、PNG、JPEG 格式
            let type = inputFile.type.split('/')[1];
            if (!['jpg', 'jpeg', 'png'].includes(type)) {
                this.$toast('请上传JPG, JPEG或者PNG格式');
                return;
            }
            this.showAvatarPopup = false;
            this.$refs.cropper.loadFile(inputFile);
        },

        // 上传图片到服务器
        uploadImgServer(inputFile) {
            const currentUrl = this.userInfo[this.uploadType];
            const cookie = {};
            document.cookie.split('; ').forEach((e) => {
                cookie[e.split('=')[0]] = e.split('=')[1];
            });
            // 添加一张本地预览图
            if (window.FileReader && this.uploadType === 'otherCode') {
                const reader = new FileReader();
                reader.readAsDataURL(inputFile);
                reader.onloadend = (e) => {
                    this.userInfo[this.uploadType] = e.target.result;
                    this.codeLoading = true;
                };
            }
            // 上传图片到腾讯云
            uploadImg(
                (res) => {
                    if (res.code === 0) {
                        this.onUploadImg(res.data.access_url);
                    } else {
                        this.$toast('图片上传失败');
                        this.onUploadImg(currentUrl);
                    }
                    this.showAvatarPopup = false;
                    this.codeLoading = false;
                },
                (err) => {
                    console.error(err);
                    this.$toast('图片上传失败');
                    this.onUploadImg(currentUrl);
                    this.codeLoading = false;
                },
                (progress) => {
                    console.info('progress = ' + progress);
                },
                inputFile,
                cookie.with_app_id
            );
        },

        // 获取裁剪后的文件对象
        getCropperFile(inputFile) {
            this.uploadImgServer(inputFile);
        },

        // 点击图片预览
        onPreviewImg(url) {
            ImagePreview({
                images: [url],
                showIndex: false,
                overlayStyle: { backgroundColor: 'rgba(0, 0, 0, 0.6)' }
            });
        },

        // 手机号输入时
        phoneInput(e) {
            // 限制手机号只能输入数字，非数字都替换掉
            this.userInfo.phone = e.target.value
                .replace(/[^\d.]/g, '')
                .replace(/^\./g, '')
                .replace(/\.{2,}/g, '.')
                .replace('.', '$#$')
                .replace(/\./g, '')
                .replace('$#$', '.')
                .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
        },

        // 保存设置
        submitSeting() {
            if (this.saveLoading) {
                return;
            }
            const select_options = this.setList.reduce((arrList, item) => {
                if (item.isSelect) {
                    arrList.push(item.field);
                }
                return arrList;
            }, []);
            const { avatar, name, phone, position, codeType, otherCode } = this.userInfo;
            if (!avatar) {
                return this.$toast('请上传头像');
            }
            if (!name) {
                return this.$toast('请输入姓名');
            }
            if (!phone) {
                return this.$toast('请输入手机号');
            }
            if (!/^1[3456789]\d{9}$/.test(phone)) {
                return this.$toast('请输入正确的手机号');
            }
            if (!position && select_options.indexOf('title') > -1) {
                return this.$toast('请输入职位');
            }
            if (codeType === '0' && !otherCode) {
                return this.$toast('请上传二维码');
            }

            const params = {
                app_id: getCookie('with_app_id'),
                user_id: localStorage.getItem('userId'),
                pic_url: this.userInfo.avatar,
                user_name: this.userInfo.name,
                phone: this.userInfo.phone,
                title: this.userInfo.position,
                qy_wechat_is_current: this.userInfo.codeType,
                qy_wechat_qr_code: this.userInfo.otherCode,
                select_options
            };
            this.saveLoading = true;
            this.$ajax('save_distribute_seting', params)
                .then((res) => {
                    if (res.data.code === 0) {
                        const { activity_detail = false } = this.$route.query;
                        this.$store.commit('setIsSetToDetail', activity_detail);
                        this.$toast('保存成功');
                        this.$router.back();
                    } else {
                        this.$toast('保存失败');
                    }
                    this.saveLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.saveLoading = false;
                    this.$toast('保存失败');
                });
        }
    }
};
</script>

<style lang="scss" scoped>
/* eslint-disable */
::v-deep .van-radio {
    margin-right: 0.48rem;
    &__label {
        font-size: 0.32rem;
        margin-left: 0.08rem;
        color: #333;
    }
}

.distribute-seting {
    width: 100%;
    min-height: 100vh;
    color: #333;
    font-weight: 400;
    background-color: #f5f5f5;
    &-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.8rem;
        padding: 0 0.32rem;
        font-size: 0.24rem;
        background-color: #fff7eb;
        .example {
            margin-left: 0.16rem;
            font-weight: 500;
            color: #1472ff;
        }
    }
    &-save {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 0.64rem);
        padding: 0 0.32rem;
        padding-bottom: costant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
        padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS<11.2*/
        height: 1.12rem;
        box-sizing: content-box;
        background-color: #fff;
        .save-button {
            width: 100%;
            height: 0.8rem;
            line-height: 0.8rem;
            text-align: center;
            font-size: 0.28rem;
            font-weight: 600;
            border-radius: 0.4rem;
            color: #fff;
            background-color: #1472ff;
        }
    }
    &-form {
        padding: 0.24rem 0.32rem;
        .form-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            border-radius: 0.16rem;
            padding: 0 0.32rem;
            margin-bottom: 0.24rem;
            .form-label {
                width: 1.52rem;
                font-size: 0.32rem;
                .required {
                    font-size: 0.28rem;
                    margin-left: 0.08rem;
                    color: #ff2929;
                }
            }
            .form-title {
                height: 0.96rem;
                line-height: 0.96rem;
                font-size: 0.32rem;
                font-weight: 500;
            }
        }
        .form-item-avatar {
            height: 1.44rem;
            .form-input {
                display: flex;
                align-items: center;
                .avatar {
                    width: 0.8rem;
                    height: 0.8rem;
                    border-radius: 50%;
                }
                .arror-icon {
                    width: 0.32rem;
                    height: 0.32rem;
                    margin-left: 0.08rem;
                }
            }
        }
        .form-item-base {
            display: block;
            .base-item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                height: 0.96rem;
            }
            .form-input {
                position: relative;
                top: 0.02rem;
                flex: 1;
                input {
                    width: 100%;
                    padding: 0.02rem 0;
                }
            }
            input::-webkit-input-placeholder {
                color: #ccc;
                font-size: 0.32rem;
            }
            input:-moz-placeholder {
                color: #ccc;
                font-size: 0.32rem;
            }
            input::-moz-placeholder {
                color: #ccc;
                font-size: 0.32rem;
            }
            input:-ms-input-placeholder {
                color: #ccc;
                font-size: 0.32rem;
            }
        }
        .form-item-qw {
            display: block;
            .form-title {
                font-weight: 500;
            }
            .checkbox-wrap {
                padding: 0.24rem 0;
                .checkbox-icon {
                    width: 0.48rem;
                    height: 0.48rem;
                }
            }
            .code-wrap {
                padding: 0.16rem 0 0.32rem;
                .code-image {
                    display: block;
                    width: 1.2rem;
                    height: 1.2rem;
                }
            }
            .upload-code {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .upload-code-btn {
                position: relative;
                width: 1.2rem;
                height: 1.2rem;
                background-color: transparent;
            }
            .remove-pic {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
                width: 0.32rem;
                height: 0.32rem;
                padding: 0;
                border: none;
                background-color: transparent;
            }
            .loading-area {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
        .form-item-information {
            display: block;
            .form-title {
                font-weight: 500;
            }
            .bar-text {
                color: #666666;
                font-size: 0.24rem;
                padding: 0.16rem 0 0.24rem;
                .example {
                    color: #1472ff;
                    cursor: pointer;
                    margin-left: 0.16rem;
                }
            }
            .set-list {
                display: flex;
                padding-bottom: 0.32rem;
            }
            .set-item {
                position: relative;
                line-height: 0.4rem;
                padding: 0.14rem 0.22rem;
                border: 0.02rem solid #ccc;
                text-align: center;
                border-radius: 0.08rem;
                margin-right: 0.24rem;
                &-select {
                    border-color: #1472ff;
                }
                &-select:after {
                    content: '';
                    position: absolute;
                    top: -0.02rem;
                    left: -0.02rem;
                    width: 0.48rem;
                    height: 0.48rem;
                    height: 0.48rem;
                    background-image: url('../../../../assets/images/select_type_icon.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                }
                // &-empty {
                //     color: #999;
                // }
                // &-empty:after {
                //     content: '未设置';
                //     position: absolute;
                //     top: 0;
                //     left: -0.02rem;
                //     width: 0.76rem;
                //     height: 0.32rem;
                //     line-height: 0.32rem;
                //     transform: translateY(-50%);
                //     text-align: center;
                //     font-size: 0.2rem;
                //     border-radius: 0.08rem 0.08rem 0.08rem 0;
                //     background-color: #bfbfbf;
                //     color: #fff;
                // }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .popup-header {
        position: relative;
        height: 1.16rem;
        line-height: 1.16rem;
        text-align: center;
        .header-title {
            font-size: 0.36rem;
            font-weight: 500;
        }
        .header-close {
            position: absolute;
            top: 0.04rem;
            right: 0.32rem;
            width: 0.24rem;
            height: 0.24rem;
            .close-icon {
                width: 100%;
                height: 100%;
            }
        }
    }
    .example-popup {
        &-content {
            padding: 0.32rem 0.64rem 0;
            .content-image {
                margin-top: 0.48rem;
            }
            .example-image {
                width: 100%;
            }
        }
    }
    .avatar-popup {
        &-content {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-top: 0.48rem;
            .avatar {
                width: 3.2rem;
                height: 3.2rem;
            }
            .avatar-change {
                width: 2.4rem;
                height: 0.8rem;
                line-height: calc(0.8rem - 0.02rem);
                margin-top: 0.48rem;
                font-size: 0.28rem;
                font-weight: 500;
                border: 0.02rem solid #ccc;
                border-radius: 0.4rem;
                text-align: center;
                box-sizing: border-box;
            }
        }
    }
}
</style>
